document.addEventListener('DOMContentLoaded', () => {
  const mobileMenuButton = document.querySelector('#mobile-menu-button')
  const mobileMenu = document.querySelector('#mobile-menu')
  const openIcon = document.querySelector('#mobile-menu-open-icon')
  const closedIcon = document.querySelector('#mobile-menu-closed-icon')

  mobileMenuButton.addEventListener('click', () => {
    mobileMenu.classList.toggle('hidden')
    openIcon.classList.toggle('hidden')
    closedIcon.classList.toggle('hidden')
  })
})
